import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Loading from './../components/Loading';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import SwipeableCard from './../components/SwipeableCard';

const ClassifiedPage = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState('latestListings');
  const [siteFilter, setSiteFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [priceDifference, setPriceDifference] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [manufacturersFilter, setManufacturersFilter] = useState([]);
  const [userRegion, setUserRegion] = useState('');
  const [locationOptions, setLocationOptions] = useState(['Wellington', 'Christchurch']); // Predefined options

  const db = getDatabase();
  const user = getAuth().currentUser;

  const apiKeyRef = ref(db, `users/${user?.uid}/settings/db_api_key`);

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        if (!user) {
          console.error('User not authenticated');
          setLoading(false);
          return;
        }

        const priceDifferenceRef = ref(db, `users/${user.uid}/filter/price_difference`);
        const filterRef = ref(db, `users/${user.uid}/filter/`);
        const userLocationRef = ref(db, `users/${user.uid}/settings/user_region`);

        const [apiKeySnapshot, priceDiffSnapshot, filterSnapshot, userRegionSnapshot] = await Promise.all([
          get(apiKeyRef),
          get(priceDifferenceRef),
          get(filterRef),
          get(userLocationRef),
        ]);

        const apiKey = apiKeySnapshot.val();
        if (!apiKey) {
          console.error('API Key not found');
          setLoading(false);
          return;
        }

        const priceDiff = priceDiffSnapshot.val() || 0;
        setPriceDifference(priceDiff);

        // Get and capitalize user region
        const fetchedUserRegion = userRegionSnapshot.val();
        if (fetchedUserRegion) {
          const formattedRegion = capitalizeFirstLetter(fetchedUserRegion);
          setLocationFilter(formattedRegion);
          setUserRegion(formattedRegion);

          // Add user region to location options if not already present
          setLocationOptions((prevOptions) => {
            if (!prevOptions.includes(formattedRegion)) {
              return [...prevOptions, formattedRegion];
            }
            return prevOptions;
          });
        }

        const filterData = filterSnapshot.val();
        let selectedManufacturers = [];
        if (filterData && filterData.manufacturers) {
          selectedManufacturers = Object.keys(filterData.manufacturers).filter(
            (key) => filterData.manufacturers[key]
          );
          setManufacturersFilter(selectedManufacturers);
        }

        // Prepare query parameters
        const params = {
          page: currentPage,
          page_size: itemsPerPage,
          site_filter: siteFilter,
          location_filter: locationFilter,
          sort_option: sortOption,
          listing_type: 'classified',  // or 'classified' for ClassifiedPage
          manufacturers_filter: manufacturersFilter,
        };
        

        // Add sorting option if necessary
        // Note: You'll need to adjust the backend to handle sorting if required

        const response = await axios.get(
          'https://api.harryludemann.com/api/v1/secure/vehiclebot/unseen/pagination',
          {
            headers: { 'X-API-KEY': apiKey },
            params: params, // Pass query parameters
          }
        );

        // Preprocess vehicles to include a parsed timestamp
        const fetchedVehicles = response.data.map((vehicle) => {
          const rawTimestamp = vehicle.timestamp;
          let parsedTimestamp = null;

          if (rawTimestamp) {
            const parsedDate = new Date(rawTimestamp.replace(' ', 'T'));
            parsedTimestamp = isNaN(parsedDate.getTime()) ? null : parsedDate.getTime();
          }

          return {
            ...vehicle,
            timestamp: parsedTimestamp,
          };
        });

        setVehicles(fetchedVehicles);
      } catch (error) {
        console.error('Error fetching classifieds:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    db,
    user,
    currentPage,
    itemsPerPage,
    sortOption,
    siteFilter,
    locationFilter,
    manufacturersFilter,
  ]);

  const markAsSeen = async (id) => {
    try {
      if (!user) {
        console.error('User not authenticated');
        return;
      }
      const apiKeySnapshot = await get(apiKeyRef);
      const apiKey = apiKeySnapshot.val();
      if (!apiKey) {
        console.error('API Key not found');
        return;
      }

      await axios.post(
        'https://api.harryludemann.com/api/v1/secure/vehiclebot/seen/',
        {
          listing_id: id,
        },
        { headers: { 'X-API-KEY': apiKey } }
      );

      // Remove the vehicle from the vehicles state
      setVehicles((prevVehicles) => prevVehicles.filter((vehicle) => vehicle.id !== id));
    } catch (error) {
      console.error('Error marking vehicle as seen:', error);
    }
  };

  // Memoize filtered vehicles
  const filteredVehicles = useMemo(() => {
    const normalizedManufacturers = manufacturersFilter.map((make) =>
      make.toLowerCase().trim()
    );

    return vehicles.filter((vehicle) => {
      if (vehicle.listing_type !== 'classified') {
        return false;
      }

      if (normalizedManufacturers.length > 0) {
        const vehicleMake = vehicle.make ? vehicle.make.toLowerCase().trim() : '';
        const makeMatches = normalizedManufacturers.some((filterMake) =>
          vehicleMake.includes(filterMake)
        );

        if (!makeMatches) {
          return false;
        }
      }

      if (siteFilter && vehicle.site !== siteFilter) {
        return false;
      }

      if (locationFilter) {
        const vehicleLocation = vehicle.location
          ? vehicle.location.toLowerCase()
          : '';
        const normalizedLocationFilter = locationFilter.toLowerCase();

        if (!vehicleLocation.includes(normalizedLocationFilter)) {
          return false;
        }
      }

      return true;
    });
  }, [vehicles, manufacturersFilter, siteFilter, locationFilter]);

  // Memoize sorted vehicles
  const sortedVehicles = useMemo(() => {
    const sorted = [...filteredVehicles].sort((a, b) => {
      switch (sortOption) {
        case 'belowMarketValue':
          return (
            (b.medium_estimate - (b.price || 0)) -
            (a.medium_estimate - (a.price || 0))
          );
        case 'latestListings':
          if (b.timestamp === null && a.timestamp === null) return 0;
          if (b.timestamp === null) return -1;
          if (a.timestamp === null) return 1;
          return b.timestamp - a.timestamp;
        case 'oldestListings':
          if (a.timestamp === null && b.timestamp === null) return 0;
          if (a.timestamp === null) return 1;
          if (b.timestamp === null) return -1;
          return a.timestamp - b.timestamp;
        case 'priceHighToLow':
          return (b.price || 0) - (a.price || 0);
        case 'priceLowToHigh':
          return (a.price || 0) - (b.price || 0);
        default:
          return 0;
      }
    });

    return sorted;
  }, [filteredVehicles, sortOption]);

  // Handle "No listings found" when filters exclude all vehicles
  const noListingsFound = sortedVehicles.length === 0 && !loading;

  // Pagination Controls
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    setLoading(true);
  };
  
  const handleNextPage = () => {
    // If fewer vehicles are returned than itemsPerPage, assume last page
    if (vehicles.length < itemsPerPage) {
      return;
    }
    setCurrentPage((prev) => prev + 1);
    setLoading(true);
  };  

  const getPriceColor = (priceDiff, userPriceDifference) => {
    if (priceDiff >= userPriceDifference * 2) {
      return 'text-green-600';
    } else if (priceDiff >= userPriceDifference) {
      return 'text-green-500';
    } else if (priceDiff >= 0) {
      return 'text-orange-500';
    } else {
      return 'text-red-500';
    }
  };

  const vehicleProperties = [
    { label: 'Make', key: 'make' },
    { label: 'Model', key: 'model' },
    { label: 'Year', key: 'year' },
    {
      label: 'Odometer',
      key: 'odometer',
      format: (value) => (value ? `${value.toLocaleString()} km` : 'N/A'),
    },
    { label: 'WOF', key: 'wof' },
    { label: 'Registration', key: 'registration' },
    { label: 'Location', key: 'location' },
    {
      label: 'Listed On',
      key: 'timestamp',
      format: (value) =>
        value ? new Date(value).toLocaleDateString() : 'Unknown',
    },
    { label: 'Site', key: 'site' },
  ];

  return (
    <div className="container mx-auto p-4 min-h-screen pt-10">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <h1 className="text-3xl font-extrabold mb-6">Classified Listings</h1>
          <div className="flex flex-col md:flex-row md:flex-wrap gap-4 mb-6">
            {/* Sort By */}
            <div className="flex flex-col w-full md:w-auto">
              <label className="font-medium mb-1 md:mr-2 md:mb-0">Sort By:</label>
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="border p-2 rounded w-full md:w-auto"
              >
                <option value="belowMarketValue">Below Market Value</option>
                <option value="latestListings">Latest Listings</option>
                <option value="oldestListings">Oldest Listings</option>
                <option value="priceHighToLow">Price High to Low</option>
                <option value="priceLowToHigh">Price Low to High</option>
              </select>
            </div>

            {/* Filter By Site */}
            <div className="flex flex-col w-full md:w-auto">
              <label className="font-medium mb-1 md:mr-2 md:mb-0">Filter By Site:</label>
              <select
                value={siteFilter}
                onChange={(e) => setSiteFilter(e.target.value)}
                className="border p-2 rounded w-full md:w-auto"
              >
                <option value="">All</option>
                <option value="turners">Turners Only</option>
                <option value="trade_me">Trade Me Only</option>
                <option value="facebook">Facebook Only</option>
              </select>
            </div>

            {/* Filter By Location */}
            <div className="flex flex-col w-full md:w-auto">
              <label className="font-medium mb-1 md:mr-2 md:mb-0">Filter By Location:</label>
              <select
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value)}
                className="border p-2 rounded w-full md:w-auto"
              >
                <option value="">All</option>
                {locationOptions.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>

            {/* Items per Page */}
            <div className="flex flex-col w-full md:w-auto">
              <label className="font-medium mb-1 md:mr-2 md:mb-0">Items per page:</label>
              <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className="border p-2 rounded w-full md:w-auto"
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>

          {noListingsFound ? (
            <p className="text-center text-gray-500">No listings found.</p>
          ) : (
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {sortedVehicles.map((vehicle) => (
                <SwipeableCard
                  key={vehicle.id}
                  vehicle={vehicle}
                  onMarkAsSeen={markAsSeen}
                  vehicleProperties={vehicleProperties}
                  getPriceColor={getPriceColor}
                  priceDifference={priceDifference}
                />
              ))}
            </ul>
          )}

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 bg-gray-200 rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-300'
              }`}
            >
              Previous
            </button>
            <span>
              Page {currentPage}
            </span>
            <button
              onClick={handleNextPage}
              disabled={vehicles.length < itemsPerPage}
              className={`px-4 py-2 bg-gray-200 rounded ${
                vehicles.length < itemsPerPage ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-300'
              }`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassifiedPage;
